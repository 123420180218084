.dashboard-card-sm {
  padding: 20px;
}
.dashboard-card-sm svg {
  font-size: 50px;
}

/* profile */
.profile-img-wrap {
  max-height: 200px;
  max-width: 200px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
}
.req-doc-close {
  right: -7px;
  top: -10px;
}
