/* -------------content area----------------- */
.content-area {
  height: calc(100vh - 83px);
  width: 100%;
  overflow: scroll;
  padding: 15px;
}

/*----------- sidebar ----------------*/
.sidebar .logo-wrapper {
  height: 83px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.logo-img {
  max-width: 60px;
  max-height: 50px;
}
.nav-and-content-area {
  width: calc(100vw - 280px);
  position: absolute;
  right: 0;
  transition: all 0.5s;
}
.sidebar {
  width: 280px;
  height: 100vh;
  position: fixed;
  transition: all 0.5s;
  overflow-y: auto;
}
.sidebar-hide {
  left: -300px;
}
.sidebar-show {
  left: 0;
}
@media only screen and (max-width: 1025px) {
  .sidebar-hide {
    left: 0;
    z-index: 1;
  }
  .sidebar-show {
    left: -300px;
    z-index: 1;
  }
  .nav-and-content-area {
    width: 100vw;
  }
}
@media only screen and (max-width: 768px) {
  .content-area {
    padding: 8px !important;
  }
}

.menu-items-wrapper {
  padding: 20px 0 20px 0;
}
.menu-ul {
  padding-left: 0;
  list-style: none;
}

.menu-title {
  margin-left: 10px;
}
.menu-item {
  color: #a7b1c2;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.2s;
  border-left: 2px solid transparent;
}
.menu-item:hover {
  background: #293846;
  color: white !important;
  transition: all 0.2s;
  border-left: 2px solid #79eb0f;
}

.submenu-icon {
  margin-left: auto;
  transition: transform 0.2s;
}

.submenu-ul {
  margin-left: 0;
  display: none;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, background-color 0.3s ease, height 0.5s ease;
  list-style-type: none;
  background-color: transparent;
  border-left: 2px solid transparent;
}

.submenu-item {
  font-size: 15px;
  color: #a7b1c2;
  transition: all 0.3s;
  display: inline-block;
  width: 100%;
  transition: all 0.2s;
  margin-left: 35px;
}
.submenu-item:hover {
  color: #ffff !important;
  font-weight: bold;
  transition: all 0.2s;
}
.submenu-item:hover.submenu-item::before {
  height: 1px;
  width: 8px;
  transition: all 0.2s;
  background-color: #ffff;
}
.submenu-active {
  color: #ffff !important;
  font-weight: bolder;
}
.submenu-active::before {
  height: 1px !important;
  width: 8px;
  transition: all 0.2s;
  background-color: #293846;
}

.visible {
  display: block;
  opacity: 1;
  height: auto;
  background-color: #293846;
  border-left: 2px solid #79eb0f;
}
.menu-active {
  background: #293846;
  color: #ffff !important;
  border-left: 2px solid #79eb0f;
}

/* -----------------navbar------------------- */

.navbar-container {
  height: 83px;
  /* position: fixed; */
}
.profile-dropdown {
  top: 35px;
  list-style-type: none;
  min-width: 8rem;
  z-index: 1;
}
.user-image-sm {
  height: 40px;
}

#profilePhotoInput {
  display: none;
}
.profileImgInputLabel {
  position: absolute;
  bottom: 10%;
  right: 7%;
  cursor: pointer;
  background-color: #3f679c;
}
.profileImgInputLabel svg {
  height: 50px;
  width: 50px;
}

.table-and-content-wrapper {
  border-radius: 12px !important;
}

.drop-menu-profile a {
  text-decoration: none;
  padding-left: 5px;
  font-size: 16px;
  transition: all 0.3s;
}
.drop-menu-profile a svg {
  font-size: 18px;
}
