* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto Serif", serif;
  color: #293846;
  background-color: #ffffff;
}
/* colors */
.primary-bg {
  background-color: #2f4050;
}
.text-primary {
  color: #2f4050;
}
.secondary-bg {
  background-color: #293846;
}
.text-secondary {
  color: #293846;
}
.fade-bg {
  background-color: rgb(245, 247, 250);
}
.text-fade {
  color: rgb(245, 247, 250);
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}

/* utilities */
.c-pointer {
  cursor: pointer;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
