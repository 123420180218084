/* input */
.pass-eye {
  right: 10px;
  bottom: 13px;
}
input,
.form-select {
  border-color: #293846;
  border: 1.5px solid !important;
  color: #2f4050;
  border-radius: 0 !important;
  padding: 10px !important;
}
input:focus,
.form-select:focus {
  box-shadow: none !important;
  border-color: #2f4050 !important;
}
/* buttons */
.btn {
  padding: 10px;
  border-radius: 0;
}
.btn-dark {
  background-color: #293846;
  color: white;
}
.btn-dark:hover {
  background-color: #2f4050;
}

/* links */
a {
  text-decoration: none;
  color: #293846;
}
/* alert */
.alert {
  position: absolute;
  font-weight: bold;
  top: -6%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-out;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.alert-visible {
  top: 6%;
  transition: all 0.2s ease-in;
}
.alert-success {
  background-color: azure !important;
  color: #2f4050;
}
.alert-danger {
  background-color: #fff;
  color: rgb(134, 8, 8);
  border-color: rgb(134, 8, 8);
}
/* table */
.table-dropdown select {
  padding: 5px 10px !important;
}
.no-data-img {
  max-height: 150px;
}

/* modal */
.modal-custom {
  min-width: 300px;
  max-width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  z-index: 2;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

/* ---------------pagination--------------- */
.pagination .page-item {
  margin: 0 5px;
}
.page-item:first-child .page-link {
  border: none;
  background-color: transparent;
}
.page-item:last-child .page-link {
  border: none;
  background-color: transparent;
}
.pagination .page-link {
  color: #293846 !important;
  border: none;
  background-color: transparent;
}
.pagination .page-item.active .page-link {
  color: #000 !important;
  background-color: #d5d8dd !important;
  border-radius: 8px;
}
.pagination .page-link:focus {
  box-shadow: none;
}

.table-search-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}
